<template>
    <div id="index" ref="appRef">
      <div class="bg">
            <dv-loading v-if="loading">Loading...</dv-loading>

            <video autoplay="true" muted playsinline loop>
                <source src="../assets/video.mp4" type="video/mp4">
            </video>

            <template v-if="!loading">
                <div class="topText userCount">
                    <CountTo :startVal='startVal' :endVal='userCount' :duration='duration' />
                </div>
                <div class="topText userAdd">
                    +
                    <CountTo :startVal='startVal' :endVal='userAdd' :duration='duration' />
                    <img src="../assets/img/index_top.png" alt="">
                </div>
                <div class="topText wxPayTotal">
                    <span style="font-size:22px; margin-right:-8px;">¥</span>
                    {{ wxPayTotal }}
                    <!-- <CountTo :startVal='startVal' :endVal='wxPayTotal' :duration='duration' /> -->
                </div>
                <div class="topText balancePayTotal">
                    <span style="font-size:22px; margin-right:-8px;">¥</span>
                    {{ balancePayTotal }}
                    <!-- <CountTo :startVal='startVal' :endVal='balancePayTotal' :duration='duration' /> -->
                </div>
                <div class="topText energyAllTotal">
                    {{ energyAllTotal }}
                    <!-- <CountTo :startVal='startVal' :endVal='energyAllTotal' :duration='duration' /> -->
                </div>
                <div class="topText energyTodayTotal">
                    <CountTo :startVal='startVal' :endVal='energyTodayTotal' :duration='duration' />
                </div>
            </template>
            

            <div class="hdp" @click="GoSwiper"></div>

            <div class="incubate" @click="Goincubate"></div>
            <!-- 孵化中心 -->
            <div class="mass" @click="Gomass"></div>
            <!-- 众创中心 -->
            <div class="capacity" @click="Gocapacity"></div>
            <!-- 产能中心 -->

      </div>

        <!-- <el-dialog
            title="今日海报总PV"
            :visible.sync="dialogVisible"
            width="581px"
            :before-close="handleClose"
            :modal="false"
        >
            <div class="shouye">

            </div>
            
        </el-dialog> -->


    </div>
  </template>
  
<script>
import drawMixin from "../utils/drawMixin";
import { index } from '../config/httpApi';
import CountTo from 'vue-count-to';
  export default {
    components: {
        CountTo
    },
    mixins: [drawMixin],
    data() {
      return {
        loading: true,
        dialogVisible:true,
        startVal:0,
        startVal1:0,
        startVal2:0,
        startVal3:0,
        startVal4:0,
        startVal5:0,
        startVal6:0,

        duration:1000,


        userCount:0,//今日用户量
        userAdd:0,//今日用户新增量
        wxPayTotal:57260,//三方支付总额
        balancePayTotal:155862,//余额支付总额
        energyAllTotal:2251118,//总能量数
        energyTodayTotal:0,//今日领取能量数
      }
    },
    mounted() {
        let that = this;
        setInterval(function() {
        //发送AJAX请求的代码
            that.loading = false;
        }, 500); //600000毫秒 = 10分钟
        that.indexl();

        setInterval(function() {
        //发送AJAX请求的代码
            that.userCount = 1000;//今日用户量
            that.userAdd = 0;//今日用户新增量
            that.wxPayTotal = '57***60';//三方支付总额
            that.balancePayTotal = '15***62';//余额支付总额
            that.energyAllTotal = '22***118';//总能量数
            that.energyTodayTotal = 1000;//今日领取能量数
            that.indexl();
        }, 10000); //60000 = 1分钟

    },
    beforeDestroy() {
    },
    methods: {
        indexl(){  //总渠道新增用户--总渠道GMV 
            index({}).then(res => {
                //后端返回的数据
                console.log(res)
                // this.indexObj = res;
                this.userCount = res.userCount;//今日用户量
                this.userAdd = res.userAdd;//今日用户新增量
                this.wxPayTotal = JSON.stringify(res.wxPayTotal).substring(0,2) + '***' + JSON.stringify(res.wxPayTotal).substring(3,6);//res.wxPayTotal;//三方支付总额
                this.balancePayTotal = JSON.stringify(res.balancePayTotal).substring(0,2) + '***' + JSON.stringify(res.balancePayTotal).substring(4,6);//余额支付总额
                this.energyAllTotal = JSON.stringify(res.energyAllTotal).substring(0,2) + '***' + JSON.stringify(res.energyAllTotal).substring(4,7);//res.energyAllTotal;//总能量数
                this.energyTodayTotal = res.energyTodayTotal;//今日领取能量数

                // this.startVal1 = res.userCount;//今日用户量
                // this.startVal2 = res.userAdd;//今日用户新增量
                // this.startVal3 = res.balancePayTotal;//余额支付总额
                // this.startVal4 = res.wxPayTotal;//三方支付总额
                // this.startVal5 = res.energyAllTotal;//总能量数
                // this.startVal6 = res.energyTodayTotal;//今日领取能量数
            })
        },
        GoSwiper(){
            this.$router.push({
                path:"/swiper",
            })
        },
        Goincubate(){ //孵化中心
            this.$router.push({
                path:"/incubate",
            })
        },
        Gomass(){ //众创中心
            this.$router.push({
                path:"/mass",
            })
        },
        Gocapacity(){ //产能中心
            this.$router.push({
                path:"/capacity",
            })
        }
    }
  }
  </script>

<style lang="scss">
.el-dialog__wrapper .el-dialog{
    width: 581px;
    height: 340px;
    background: #f60;
    background: url('../assets/img/shouye_bj.png') no-repeat;
    background-size: 581px 340px;
}
.el-dialog__wrapper .el-dialog__header{
    text-align: center;
}
.el-dialog__wrapper .el-dialog__header .el-dialog__title{
    
    height: 33px;
    font-size: 24px;
    font-weight: 400;
    color: #0EFFFF !important;
    line-height: 33px;
}
.el-dialog__body{
    .shouye{
        width: 496px;
        height: 213px;
        background: url('../assets/img/shouye_bj1.png') no-repeat;
        background-size: 496px 213px;
        margin: 0 auto
    }
}
</style>
<style lang="scss" scoped>
@import '../assets/scss/index.scss';

.bg{
    position: relative;

    .topText{
        position: absolute;
        color: #78e3fc;
        z-index: 999;
        font-size: 32px;
        font-family: DINAlternate-Bold, DINAlternate;
    }
    .userCount{
        top: 214px;
        left: 272px;
    }
    .userAdd{
        font-size: 20px;
        top: 214px;
        left: 410px;
    }
    .wxPayTotal{
        top: 212px;
        left: 735px;
        font-size: 28px;
    }
    .balancePayTotal{
        top: 212px;
        left: 985px;
        font-size: 28px;
    }
    .energyAllTotal{
        top: 208px;
        left: 1350px;
        font-size: 26px;
        // width: 118px;
        height: 30px;
        line-height: 30px;
        // overflow: hidden;
        // white-space: nowrap;
        // text-overflow: ellipsis;
        // background: #f60;
        // opacity: .2;
    }
    .energyTodayTotal{
        top: 212px;
        left: 1605px;
        font-size: 30px;
    }



    .hdp{
        width: 150px;
        height: 40px;
        position: absolute;
        top: 45px;
        right: 30px;
        z-index: 999;
        opacity: .3;
        cursor: pointer;
        // background: #f60;
    }

    .incubate{  //孵化中心
        width: 250px;
        height: 80px;
        position: absolute;
        top: 940px;
        left: 205px;
        z-index: 999;
        opacity: .3;
        // background: #f60;
        cursor: pointer;
    }

    .mass{  //众创中心
        width: 250px;
        height: 80px;
        position: absolute;
        top: 300px;
        left: 1065px;
        z-index: 999;
        opacity: .3;
        // background: #f60;
        cursor: pointer;
    }

    .capacity{  //产能中心
        width: 250px;
        height: 80px;
        position: absolute;
        top: 390px;
        left: 600px;
        z-index: 999;
        opacity: .3;
        // background: #f60;
        cursor: pointer;
    }
    
}
</style>
  